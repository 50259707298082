import React, { lazy, Suspense } from 'react'
import {Route,  Routes } from 'react-router-dom'
import CreateSite from '../CreateSite/CreateSite'

const Home = lazy(() => import('../Home/Home'))
const Registration = lazy(() => import('../Cabinet/Registration/Registration'))
const Contact = lazy(() => import('../Contact/Contact'))
const Projects = lazy(() => import('../Projects/Projects'))
const NotFoundPage = lazy(() => import('../NotFoundPage/NotFoundPage'))


const App = () => {  
  return (
    <>
      <Routes>
        <Route path="/" element={<CreateSite/>}/>
        <Route path="/home" element={
          <Suspense>
            <Home/>
          </Suspense>
        }/>
        <Route path="/authorization" element={
            <Suspense>
              <Registration/>
            </Suspense>
          }/>
        {/* <Route path="/personal-account" element={<PersonalAccount/>}/> */}
        <Route path="/contact" element={
            <Suspense>
              <Contact/>
            </Suspense>
          }/>
        <Route path="/projects" element={
            <Suspense>
              <Projects/>
            </Suspense>
          }/>
        <Route path="*" exact={false} element={
            <Suspense>
              <NotFoundPage/>
            </Suspense>
          }/>
          {/* <Route
            path="*"
            element={<Navigate to="/" replace />} //this is a way to redirect
          /> */}
      </Routes>
    </>
  )
}
export default App



