import { createActions } from 'redux-actions'

import * as constants from '../constants/registrationAction'


export const { postLogin, postLoginSuccess, postRegistration, postRegistrationSuccess, auth, error, resetError} =
  
createActions(
  constants.POST_LOGIN,
  constants.POST_LOGIN_SUCCESS,
  constants.POST_REGISTRATION,
  constants.POST_REGISTRATION_SUCCESS,
  constants.AUTH,
  constants.ERROR,
  constants.RESET_ERROR
)