/* eslint-disable import/no-anonymous-default-export */
import * as actions from './../actions/closeModalAction'

export const closeModal = {
  showModal: null,
  showAlert: null,
}

const HANDLERS = {
  [actions.closeModal]: (state, data) => {
    return {
      ...state,
      showModal: data,
    }
  },
  [actions.showAlertAction]: (state, data) => {
    return {
      ...state,
      showAlert: data,
    }
  }
}

export default (state = closeModal, { type, payload }) => {
  const handler = HANDLERS[type]
  return handler ? handler(state, payload) : state
}