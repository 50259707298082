/* eslint-disable import/no-anonymous-default-export */
import * as actions from './../actions/registrationAction'
import { putPersonalDataSuccess } from './../actions/personalDataAction'
export const getToken = {
  token: false,
  userName: '',
  auth: 'registration',
  error: null,
}

const HANDLERS = {
  [actions.postLoginSuccess]: (state, data) => {
    return {
      ...state,
      token: data.token ? true : false,
      userName: data.userName
    }
  },
  [actions.postRegistrationSuccess]: (state, data) => {
    console.log(data)
    return {
      ...state,
      token: data.token ? true : false,
      userName: data.userName
    }
  },
  [actions.auth]: (state, data) => {
    return {
      ...state,
      auth: data,
      token: false
    }
  },
  [actions.error]: (state, data) => {
    return {
      ...state,
      error: data
    }
  },
  [actions.resetError]: (state, data) => {
    return {
      ...state,
      error: null
    }
  },
  [putPersonalDataSuccess]: (state, data) => {
    console.log(data)
    return {
      ...state,
      userName: data.userName
    }
  },
}

export default (state = getToken, { type, payload }) => {
  const handler = HANDLERS[type]
  return handler ? handler(state, payload) : state
}