import React from 'react'
import ModalWrapper from '../../wrapers/ModalWrarrer/ModalWrapper'
import { SmileOutlined } from '@ant-design/icons'

import style from './ModalAlert.module.scss'

export default function ModalAlert(props) {
  return (

    <ModalWrapper showAlert={props.showAlert}>
      <div className={style.wrapAlert}>
          <div className={style.wrapTitle}>
              <h2>Success</h2>
          </div>
          <span className={style.textAlert}>We will call you</span>
          <SmileOutlined className={style.smile}/>
        </div>
    </ModalWrapper>
  )
}
