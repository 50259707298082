export const adviсes = [
    {
        tytle: "Как создать впечатляющий сайт?"
    },
    {
        text: ''
    },
    {
        content: [
            {
                id: 1,
                tytle: "Определение целей и целевой аудитории: ",
                text: "Прежде чем приступить к созданию сайта, необходимо определить, какие цели вы хотите достичь и кто ваша целевая аудитория. Это поможет вам создать сайт, который будет соответствовать ожиданиям и потребностям пользователей."
            },
            {
                id: 2,
                tytle: "Планирование структуры и контента: ",
                text: "Составьте план структуры вашего сайта и определите, какой контент будет размещен на каждой странице. Важно предусмотреть удобную навигацию и логическую организацию информации."
            },
            {
                id: 3,
                tytle: "Создание эффективного дизайна: ",
                text: ""
            },
            {
                id: 4,
                tytle: "Разработка адаптивного и SEO-оптимизированного сайта: ",
                text: "В настоящее время большинство пользователей используют мобильные устройства для доступа к интернету. Убедитесь, что ваш сайт отзывчивый и легко просматривается на различных устройствах. Также уделите внимание оптимизации сайта для поисковых систем (SEO), чтобы повысить его видимость и посещаемость."
            },
            {
                id: 5,
                tytle: "Разработка функциональности и взаимодействия: ",
                text: "Ваш сайт должен обладать не только хорошим дизайном, но и функциональностью. Разработайте интерактивные элементы, такие как формы обратной связи, возможность комментирования и социальная интеграция, чтобы улучшить взаимодействие пользователей с вашим сайтом."
            },
            {
                id: 6,
                tytle: "Тестирование и отладка: ",
                text: "Тестирование и отладка: Перед запуском вашего сайта проведите тестирование, чтобы убедиться, что все функции работают должным образом и что нет ошибок или сбоев. Исправьте все проблемы и проверьте еще раз, чтобы быть уверенным в отличной работе сайта."
            },
            {
                id: 7,
                tytle: "Развитие и поддержка: ",
                text: "Развитие и поддержка: Создание сайта - это только начало. Постоянно развивайте и обновляйте свой сайт, чтобы он оставался актуальным и привлекательным для посетителей. Также обеспечьте его регулярную поддержку и обновление, чтобы сохранить его быструю и безопасную работу."
            }
        ]
    }
    
]

export const design = [
    {
        tytle: "Как создать эффективный дизайна сайта (веб-дизайн)?"
    },
    {
        text: "Веб-дизайн играет важную роль в формировании впечатления посетителей о вашем сайте. \n В этой статье мы рассмотрим 5 основных принципов, которые помогут вам создать эффективный веб-дизайн, который привлечет и удержит внимание пользователей."
    },
    {
        content: [
            {
                id: 1,
                tytle: "Удобство использования (usability): ",
                text: "Веб-сайт должен быть легким в использовании и навигации. Навигационная структура должна быть понятной и интуитивно понятной, а элементы управления должны быть доступными и простыми в использовании для пользователей."
            },
            {
                id: 2,
                tytle: "Скорость загрузки (loading speed): ",
                text: "Веб-сайт должен загружаться быстро, чтобы пользователи не теряли терпение и не покидали его. Это достигается путем использования современных технологий, таких как React или Angular, а также оптимизации изображений и ограничения использования сложных или излишне больших файлов."
            },
            {
                id: 3,
                tytle: "Целевая аудитория (target audience): ",
                text: "Дизайн должен быть адаптирован под нужды и предпочтения вашей целевой аудитории. Это включает выбор цветовой схемы, шрифтов, размещение контента и т. д. Зная свою аудиторию, вы можете создать более эффективный дизайн, который будет привлекать и удерживать их внимание."
            },
            {
                id: 4,
                tytle: "Мобильная адаптивность (mobile responsiveness): ",
                text: "С увеличением использования мобильных устройств, веб-дизайн должен быть адаптирован под различные экраны и устройства. Веб-сайт должен быть отзывчивым и легко читаемым на маленьких экранах, чтобы обеспечить хорошее пользовательское впечатление."
            },
            {
                id: 5,
                tytle: "Визуальная привлекательность (visual appeal): ",
                text: "Дизайн веб-сайта должен быть эстетически привлекательным и соответствовать бренду компании или организации. Использование привлекательных изображений, макетов и цветов может помочь привлечь внимание пользователей и заинтересовать их контентом."
            }
        ]
    }
]

export const mobileApp = [
    {
        tytle: "Как самостоятельно разработать мобильное приложение?"
    },
    {
        text: "Создание мобильного приложения может быть достаточно сложным процессом, но мы можем предложить следующие поэтапные шаги для самостоятельной разработки:"
    },
    {
        content: [
            {
                id: 1,
                tytle: "Определение цели и темы приложения: ",
                text: "Прежде всего, определитесь с целью вашего приложения и его основной тематикой. Например, это может быть социальная сеть, электронная коммерция или игра."
            },
            {
                id: 2,
                tytle: "Исследование рынка: ",
                text: "Изучите рынок и конкурентов в выбранной вами тематике. Определите, какие функции и возможности уже предлагают другие приложения, и чем ваше приложение будет отличаться."
            },
            {
                id: 3,
                tytle: "Написание спецификаций: ",
                text: "Создайте документ с описанием функциональных и нефункциональных требований к вашему приложению. Это поможет определить основные функции, дизайн и взаимодействие с пользователями."
            },
            {
                id: 4,
                tytle: "Проектирование интерфейса: ",
                text: "Разработайте прототип интерфейса вашего приложения. Используйте специальные инструменты, такие как Figma или Sketch, для создания макетов экранов и взаимодействия между ними."
            },
            {
                id: 5,
                tytle: "Разработка: ",
                text: "Выберите подходящий фреймворк или платформу для разработки мобильных приложений, такую как React Native (возможно проектирование одновременно для IOS и Андроид), Flutter или Swift (для iOS) / Kotlin (для Android). Начинайте создавать основные функции вашего приложения, используя язык программирования, поддерживаемый выбранным фреймворком."
            },
            {
                id: 6,
                tytle: "Тестирование: ",
                text: "После завершения разработки проведите тестирование вашего приложения. Убедитесь, что все функции работают правильно и приложение стабильно работает на различных устройствах."
            },
            {
                id: 7,
                tytle: "Релиз: ",
                text: "Подготовьте ваше приложение к релизу. Опубликуйте его в соответствующих магазинах приложений, таких как App Store или Google Play. Убедитесь, что вы выполнили все требования для публикации и предоставили достаточно информации о вашем приложении пользователям."
            },
            {
                id: 8,
                tytle: "Поддержка и обновления: ",
                text: "После выпуска приложения продолжайте поддерживать его, исправляйте ошибки и предлагайте новые функции. Взаимодействуйте с пользователями, прослушивайте их отзывы и постоянно улучшайте ваше приложение."
            }

        ]
    }
]

export const choosePL = [
    {
        tytle: "Какой язык программирования использовать для создания сайтов?"
    },
    {
        text: "Правильный выбор языка программирования для создания вэб-приложений и сайтов поможет вам существенно сэкономить ваше время."
    },
    {
        content: [
            {
                id: 1,
                tytle: "",
                text: "Среди языков программирования существует большой выбор, однако для веб-разработки, в том числе при создании сайта, самый популярный и востребованный уже более десяти лет является JavaScript."
            },
            {
                id: 2,
                tytle: "",
                text: "JavaScript - мощный и универсальный язык программирования, который позволяет создавать динамические и интерактивные веб-сайты. Преимущества JavaScript включают его широкую поддержку веб-браузерами, возможность создания анимаций, обработку пользовательских событий и многое другое. Более того, с появлением Node.js, JavaScript также может быть использован на серверной стороне (backend) для создания полноценных веб-приложений."
            },
            {
                id: 3,
                tytle: "",
                text: "Благодаря своей мощности и гибкости, JavaScript часто используется в комбинации с другими технологиями, такими как HTML и CSS или SCSS, для создания современных и функциональных веб-сайтов."
            },
            {
                id: 4,
                tytle: "",
                text: "Кроме того, существуют фреймворки и библиотеки, такие как React, Angular и Vue, которые совершенствуют и облегчают разработку веб-приложений на различных этапах программирования. React заслуживает особого внимания. React - это JavaScript-библиотека, разработанная Facebook, которая наиболее эффективна при создание масштабируемых и быстрых пользовательских интерфейсов."
            },
            {
                id: 5,
                tytle: "",
                text: "Однако, помимо JavaScript, существует также множество других языков, которые могут быть использованы при создании сайтов. Java, Python и Ruby также довольно популярны в веб-разработке и могут быть использованы для создания серверной логики."
            },
            {
                id: 6,
                tytle: "",
                text: "Также необходимо учитывать, что выбор языка программирования и фреймворка зависит от многих факторов, включая ваши потребности, опыт команды разработчиков и требования проекта. Рекомендуется изучить оба языка, JavaScript и React, и принять решение, основываясь на особенностях и требованиях вашего конкретного проекта."
            }
        ]
    }   
]

export const onlineStoreCost = [
    {
        tytle: "От чего зависит стоимость интернет магазина?"
    },
    {
        text: "Стоимость разработки интернет-магазина является результатом взаимодействия различных факторов, объединяющих технические, дизайнерские, и бизнес-аспекты проекта, а также уникальные потребности заказчика. \n Определение цены включает в себя следующие ключевые факторы:"
    },
    {
        content: [
            {
                id: 1,
                tytle: "Функциональные требования:",
                text: "Сложность функционала магазина непосредственно влияет на затраты. Например, добавление продвинутых фильтров, интеграция с внешними сервисами, подписки, и другие дополнительные возможности повышают стоимость разработки."
            },
            {
                id: 2,
                tytle: "Дизайн и удобный интерфейс:",
                text: "Качественный и уникальный дизайн, который соответствует бренду, и удобный пользовательский интерфейс требуют некоторых дополнительных ресурсов. Адаптация дизайна под различные устройства также может повысить стоимость."
            },
            {
                id: 3,
                tytle: "Интеграция сторонних сервисов:",
                text: "Если требуется интегрировать системы управления складом и другие сторонние сервисы, это влияет на цену интернет-магазина из-за необходимости внедрения и тестирования дополнительных компонентов."
            },
            {
                id: 4,
                tytle: "Уровень безопасности",
                text: "Защита данных клиентов, использование SSL-сертификатов непосредственно не сильно влияют на стоимость, но также являются неотъемлемой частью успешного и безопасного интернет-магазина."
            },
            {
                id: 5,
                tytle: "Тип платформы и технологии:",
                text: "Выбор технологии разработки и платформы, такой как WordPress, Magento, Shopify, или разработка на основе фреймворка, может оказать влияние на стоимость проекта."
            },
            {
                id: 6,
                tytle: "Масштаб проекта:",
                text: "Размер и сложность интернет-магазина, количество товаров, предусмотренные возможности для масштабирования – все эти факторы оказывают влиянин на общую стоимость интернет-магазина."
            },
            {
                id: 7,
                tytle: "Сроки разработки:",
                text: "Срочность разработки может потребовать дополнительных ресурсов и, следовательно, повысить стоимость."
            },
            {
                id: 8,
                tytle: "Поддержка и обслуживание:",
                text: "Расходы на поддержку и обслуживание после запуска магазина также нужно учесть в общем бюджете проекта."
            },
            {
                id: 9,
                tytle: "Бюджет на маркетинг и рекламу:",
                text: "Расходы на маркетинг и рекламу на прямую не влияют на цену интернет-магазина, однако его необходимо учитывать при запуске вашего проекта."
            },
        ]
    }   
]
