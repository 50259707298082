import React, { useState } from 'react'
import ModalWrapper from '../../wrapers/ModalWrarrer/ModalWrapper'

import style from './Technologies.module.scss'

export default function Technologies({fontFamily}) {

  const [pageSpeedLT, setPageSpeedLT] = useState(false)
  const [pageSpeedMobile, setPageSpeedMobile] = useState(false)

  const onCloseModal = () => {
    setPageSpeedLT(false)
    setPageSpeedMobile(false)
  }
  return (
    <div className={style.wrapSteps} style={{fontFamily: fontFamily ? 'Lobster' : ''}}>
      <div className={style.stepsTitle}>
        {
          fontFamily
          ? <span className={style.title}>Технологии разработки</span>
          : <img className={style.titleReact} src={require('./images/technologies.png')} alt=''/>
        }
      </div>
      <div className={style.stepsBlock}>
        <div className={style.description}>
          &nbsp; Данный сайт написан на современной технологии <span style={{fontWeight: 'bold'}}>React</span>.<br/>
          &nbsp; Мобильное приложение для вас будет реализовано на технологии <span style={{fontWeight: 'bold'}}>React Native</span>.<br/>
          &nbsp; Ниже приведены преимущества сайта, разработанного на React, по сравнению с другими технологиями.
        </div>
        <div className={style.steps}>
          <img className={style.circleReact} rel= "prefetch" title='100%' loading='lazy' width='195' height='110' src={require('./images/100.webp')} alt='100%'/>
          <span className={style.text}><span className={style.textBold}>1. Эффективный рендеринг</span><br/>React использует виртуальный DOM, который позволяет обновлять только измененные компоненты, минимизируя количество операций по обновлению. </span>
        </div>
        <div className={style.steps}>
          <img className={style.circleReact} rel= "prefetch" title='react' loading='lazy' width='195' height='110' src={require('./images/react1.webp')} alt='react'/>
          <span className={style.text} style={{marginTop: 0}}><span className={style.textBold}>2. Высокая скорость и производительность</span><br/>Благодаря эффективному рендерингу и обработке событий, <span className={style.textReact}> сайты и приложения на React работают намного быстрее </span> по сравнению с сайтами/приложениями, написанными на других технологиях (фреймворках или библиотеках), что несомненно очень важно для интернет магазинов с широкой линейкой товаров, высоконагруженных приложений и для крупных корпоративных сайтов.</span>
        </div>
        <div className={style.steps}>
          <img className={style.circleReact} rel= "prefetch" title='react' loading='lazy' width='195' height='110' src={require('./images/pagespeed.webp')} alt='react'/>
          <span className={style.text} style={{marginTop: 0}}><span className={style.textBold}>3.PageSpeed Insights</span><br/>PageSpeed Insights - это инструмент от Google, который оценивает и предоставляет отчет о производительности и скорости загрузки веб-страниц. Помимо этого он <span style={{fontWeight: 'bold'}}>анализирует оптимизацию сайта под поисковые запросы</span> и различные элементы веб-страницы, включая изображения, CSS и JavaScript-файлы, кэширование и другие параметры, которые могут влиять на скорость загрузки.
            <br/><span className={style.pageSpeed}
              onClick={() => setPageSpeedLT(true)}
            >PageSpeed этого сайта для стационарных компьютеров, ноутбуков и laptop</span>
            <br/><span className={style.pageSpeed}
              onClick={() => setPageSpeedMobile(true)}
            >PageSpeed этого сайта для всех мобильных устройств</span> (учитывает намеренно сниженную скорость интернета)
          </span>
        </div>
        <div className={style.steps}>
            <img className={style.circleReact} rel= "prefetch" title='community' loading='lazy' width='195' height='110' src={require('./images/community.webp')} alt='community'/>
            <span className={style.text}><span className={style.textBold}>4. Большое сообщество</span><br/><span style={{fontWeight: 'bold'}}>Создатели React - компания Facebook,</span> предоставила открытый исходный код любым желающим. И на сегодняшний день на React написаны не только Meta, Instagram, WhatsApp и Oculus, но и большая половина современных сайтов и приложений. 
            Огромное сообщество разработчиков предлагает разработку и поддержку проекта, а также множество готовых решений, библиотек и документации для вашего проекта.</span>
        </div>
        <div className={style.steps}>
            <img className={style.circleReact} rel= "prefetch" title='module' loading='lazy' width='195' height='110'  src={require('./images/rn.webp')} alt='react native'/>
          <span className={style.text}><span className={style.textBold}>5. Мобильная разработка</span><br/><span style={{fontWeight: 'bold'}}>React Native</span>, основанный на React, позволяет разрабатывать мобильные приложения используя <span className={style.textBold}>один код для iOS и Android</span> одновременно, применяя JavaScript и React-подобный подход. 
            Такая технология существенно экономит время и усилия при разработке, а следовательно и стоимость мобильного приложения.
            <br/>Боле подробную информацию <a style={{color: '#4F443B', fontWeight: 'bold'}} href="https://заказать-мобильное-приложение.бел/">разработке мобильных приложений</a> можете узнать на сайте нашей компании <a style={{color: '#de3a11', fontWeight: 'bold'}} href="https://заказать-мобильное-приложение.бел/">Заказать-мобильное-приложение.бел</a>
            </span>
        </div>
        <div className={style.steps}>
            <img className={style.circleReact} rel= "prefetch" title='module' loading='lazy' width='195' height='110'  src={require('./images/module.webp')} alt='module'/>
          <span className={style.text}><span className={style.textBold}>6. Модульность</span><br/>React предлагает использование компонентов, которые могут быть повторно использованы и управляемы независимо друг от друга. Это позволяет приложению масштабироваться, улучшает поддержку кода и упрощает его разработку.</span>
        </div>
        <div className={style.steps}>
            <img className={style.circleReact} rel= "prefetch" title='code' loading='lazy' width='195' height='110' src={require('./images/code.webp')} alt='code'/>
          <span className={style.text}><span className={style.textBold}>7. Односторонний поток данных</span><br/>React использует односторонний поток данных, что позволяет более просто отслеживать и управлять обновлениями состояний компонентов.</span>
        </div>
        <div className={style.steps}>
          <img className={style.circleReact} rel= "prefetch" title='proger' loading='lazy' width='195' height='110' src={require('./images/proger.webp')} alt='proger'/>
          <span className={style.text}><span className={style.textBold}>8. Удобство разработки</span><br/>React использует JSX (язык похожий на HTML), что делает разработку компонентов более интуитивной и удобной для понимания.</span>
        </div>
        <div className={style.steps}>
          <img className={style.circleReact} rel= "prefetch" title='test' loading='lazy' width='195' height='110' src={require('./images/test1.webp')} alt='test'/>
          <span className={style.text}><span className={style.textBold}>9. Легкое тестирование</span><br/>React предлагает инструменты, которые облегчают тестирование компонентов, что помогает обнаружить и исправить ошибки на ранних стадиях разработки.</span>
        </div>
        <span className={style.text}>
          &nbsp; Но стоит отметить, что выбор фреймворка или библиотеки зависит от конкретного проекта и его требований.<br/>
          &nbsp; Мы также используем в разработке технологии, такие как: Wix, Битрикс24, Tilda и другие для верстки самых простых макетных сайтов и интернет магазинов с поддержкой  Wordpress CMS, Joomla (системы управления содержимым сайтов )
        </span>
      </div>
      <ModalWrapper showModal={pageSpeedLT ? pageSpeedLT : pageSpeedMobile ? pageSpeedMobile : false}>
          <div className={style.wrapModal}>
            <div
              className={style.close}
              onClick={() => onCloseModal()}
            ><img src={require('./images/X.png')} loading='lazy' style={{ width: 14, height: 14 }} alt="X" draggable="false"/>
            </div>
            {
              pageSpeedLT && <img className={style.pageSpeedImg} title='proger' loading='lazy' width='900' height='471' src={require('./images/pagespeedLT.png')} alt='proger'/>
            }
            {
              pageSpeedMobile && <img className={style.pageSpeedImg} title='proger' loading='lazy' width='900' height='471' src={require('./images/pagespeedMobile.png')} alt='proger'/>
            }
          </div>
        </ModalWrapper>
    </div>
  )
}
