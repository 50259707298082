import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import animate from './animateReduser'
import closeModal from './closeModalReduser'
import getToken from './registrationReducer'

export const history = createBrowserHistory()

const staticReducers = {
  router: connectRouter(history),
  animate,
  closeModal,
  getToken
}

export default staticReducers
