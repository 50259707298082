import React, { useState } from 'react'
import Content from './Content'
import { design, adviсes, mobileApp, choosePL, onlineStoreCost } from '../../data/data'

import style from './Articles.module.scss'

export default function Articles() {

    const [showDesignContent, setDesignContent] = useState(false),
        [showAdviсesContent, setAdviсesContent] = useState(true),
        [showMobileAppContent, setMobileAppContent] = useState(false),
        [showChoosePLContent, setChoosePLContent] = useState(false),
        [showOnlineStoreCost, setOnlineStoreCost] = useState(false)

    const images1 =[
        <img className={style.circle} loading='lazy' title='number 1' src={require('./images/1.webp')} alt='number 1'/>,
        <img className={style.circle} loading='lazy' title='number 2' src={require('./images/2.webp')} alt='number 2'/>,
        <img className={style.circle} loading='lazy' title='number 3' src={require('./images/3.webp')} alt='number 3'/>,
        <img className={style.circle} loading='lazy' title='number 4' src={require('./images/4.webp')} alt='number 4'/>,
        <img className={style.circle} loading='lazy' title='number 5' src={require('./images/5.webp')} alt='number 5'/>,
        <img className={style.circle} loading='lazy' title='number 6' src={require('./images/6.webp')} alt='number 6'/>,
        <img className={style.circle} loading='lazy' title='number 7' src={require('./images/7.webp')} alt='number 7'/>,
        <img className={style.circle} loading='lazy' title='number 8' src={require('./images/8.webp')} alt='number 8'/>
    ]

    const designContent = design[2].content.map(item => {
        return <Content
            id={item.id}
            tytle={item.tytle}
            text={item.text}
            key={item.id}
        />
    })
    const adviсesContent = adviсes[2].content.map((item, index) => {
        return <Content
            id={images1[index]}
            tytle={item.tytle}
            text={item.id === 3 ? 
                <span>Дизайн вашего сайта должен быть привлекательным и профессиональным. Уделите внимание цветам, шрифтам, изображениям и макету, чтобы создать визуально привлекательный и удобный для использования сайт. 
                    <br/>Посмотрите <a onClick={() => setDesignContent(item => !item)} style={{color: '#4F443B', fontWeight: 'bold'}} href='#принципы создания эффективного вэб-дизайна'>основные принципы создания эффективного вэб-дизайна</a>
                </span>
                : item.text}
            key={item.id}
        />
    })
    const mobileAppContent = mobileApp[2].content.map((item, index) => {
        return <Content
            id={images1[index]}
            tytle={item.tytle}
            text={item.text}
            key={item.id}
        />
    })
    const choosePLContent = choosePL[2].content.map(item => {
        return <Content
            id=''
            tytle={item.tytle}
            text={item.text}
            key={item.id}
        />
    })
    const onlineStoreCostContent = onlineStoreCost[2].content.map(item => {
        return <Content
            id={item.id}
            tytle={item.tytle}
            text={item.text}
            key={item.id}
        />
    })
  return (
    <div className={style.wrapArticles}>
        <div className={style.wrapTytle}>
            <span className={style.tytle}>Интересные статьи</span>
        </div>
        <div className={showAdviсesContent ? style.showContent : style.tytleWrap}
            style={{whiteSpace: 'pre-wrap'}}
            onClick={() => setAdviсesContent(item => !item)}
        >
            <div className={style.tytle}>
                {adviсes[0].tytle}
            </div>

            <span className={style.text}>
                Создание впечатляющего сайта требует некоторого планирования и реализации. Вы можете выбрать конструктор при создании сайта или посвятить какое-то время на изучение языка программирования. 
                <br/>Кстати, <span style={{fontWeight: 'bold'}}>выбор языка программирования</span> при создании вэб-приложения освещен в <a onClick={() => setChoosePLContent(item => !item)} style={{color: '#4F443B', fontWeight: 'bold'}} href='#выбор языка программирования'>следующей статье</a>. 
                <br/>В этой статье мы рассмотрим 7 важных шагов, которые помогут вам создать сайт, который запомнится посетителям.
            </span>
            {adviсesContent}
        </div>
        
        <div className={showDesignContent ? style.showContent : style.tytleWrap}
            onClick={() => setDesignContent(item => !item)}
        >
            <div className={style.tytle}>
                <a name="принципы создания эффективного вэб-дизайна"></a>
                {design[0].tytle}
            </div>
            <span className={style.text}>
                {design[1].text}
            </span>
            {designContent}
        </div>

        <div className={showChoosePLContent ? style.showContent : style.tytleWrap}
            onClick={() => setChoosePLContent(item => !item)}
        >
            <div className={style.tytle}>
                <a name="выбор языка программирования"></a>
                {choosePL[0].tytle}
            </div>
            <span className={style.text}>
                {choosePL[1].text}
            </span>
            {choosePLContent}
        </div>

        <div className={showOnlineStoreCost ? style.showContent : style.tytleWrap}
            onClick={() => setOnlineStoreCost(item => !item)}
        >
            <div className={style.tytle}>
                {onlineStoreCost[0].tytle}
            </div>
            <span className={style.text}>
                {onlineStoreCost[1].text}
            </span>
            {onlineStoreCostContent}
        </div>

        <div className={showMobileAppContent ? style.showContent : style.tytleWrap}
            onClick={() => setMobileAppContent(item => !item)}
        >
            <div className={style.tytle}>
                {mobileApp[0].tytle}
            </div>
            <span className={style.text}>
                {mobileApp[1].text}
            </span>
            {mobileAppContent}
        </div>
    </div>
  )
}
