/* eslint-disable import/no-anonymous-default-export */
import * as actions from './../actions/animateAction'

export const animate = {
  showAnimate: false,
}

const HANDLERS = {
  [actions.animate]: (state, data) => {
    return {
      ...state,
      showAnimate: data,
    }
  },
}

export default (state = animate, { type, payload }) => {
  const handler = HANDLERS[type]
  return handler ? handler(state, payload) : state
}