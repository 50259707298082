import React from 'react'
import style from './Envelop.module.scss'

export default function Envelop({size, position}) {
  return (
    <div className={style.envelop} style={{position: position, right: 0, top: size}}>
        <div className={style.message}>Message</div>
    </div>
  )
}
