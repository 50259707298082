import React from 'react'
import style from './Messengers.module.scss'

export default function Messengers({ direction, size, sisePhone, margin }) {
  return (
    <div className={style.wrapMessengers} style={{flexDirection: direction, marginBottom: margin}}>
        <a href="tel:+375259802774" aria-label="phone"><i className="fas fa-phone-square-alt"></i><b>
          <img src={require('./images/phone.png')} rel= "prefetch" loading='lazy' style={{borderRadius: 40, width: size, height: size, margin: 4}} alt=''/>
        </b></a>
        <a title="Telegram" href="tg://resolve?phone=+375259802774" aria-label="telegram">
          <img src={require('./images/telegram.png')} rel= "prefetch" loading='lazy' style={{width: size, height: size, margin: 4}} alt=''/>
        </a> 
        <a href="viber://chat/?number=%2B375259802774" aria-label="viber">
          <img src={require('./images/viber.png')} rel= "prefetch" loading='lazy' style={{width: size, height: size, margin: 4}} alt=''/>
        </a>
        <a href="mailto:p_filonchyk@mail.ru" aria-label="email">
          <img src={require('./images/email.png')} rel= "prefetch" loading='lazy' style={{width: size, height: size, margin: 4}} alt=''/>
        </a>
    </div>
  )
}
