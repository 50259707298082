import React, { useRef } from 'react'
import ReactGA from "react-ga4"
import emailjs from "@emailjs/browser"
import ym from 'react-yandex-metrika'
import { useDispatch } from 'react-redux'
import { sendMetrik } from '../../utils/metriks'
import { closeModal, showAlertAction } from '../../core/actions/closeModalAction'

import style from './ModalEmail.module.scss'

export default function ModalEmail(props) {
    
  const dispatch = useDispatch()

  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault()
  
    emailjs
      .sendForm(
        "service_vl3y38b",
        "template_ejzxsi6",
        form.current,
        "xcmO39CvnpjLuh6Km",
        
      )
      .then(
        (result) => {
          e.target.reset()
        },
        (error) => {
          console.error(error.text)
        }
      )
    dispatch(closeModal(false))
    dispatch(showAlertAction(true))
  }
  const analytics = () => {
    ym(94127841,'hit', 'reachGoal','click')
    ReactGA.send({ hitType: "send", page: "../CreateSite/CreateSite.jsx", title: "Отправка формы" })
  }
  return (
    <form className={style.form} ref={form} onSubmit={sendEmail}>
      <label className={style.label}>Вашe имя</label>
      <input className={style.input} type="text" name="user_name" required/>
      <label className={style.label}>Email</label>
      <input className={style.input} type="email" name="user_email" required/>
      <label className={style.label}>Телефон</label>
      <input className={style.input} type="text" name="user_phone" required/>
      {props.children}
      <input type="submit" value="Отправить"
        onClick={() => analytics()}
      />
    </form>
  )
}
