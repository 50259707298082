import { createActions } from 'redux-actions'

import * as constants from '../constants/personalDataAction'

export const { putPersonalData, putPersonalDataSuccess, postPersonalData, postPersonalDataSuccess } =
  
createActions(
  constants.PUT_PERSONAL_DATA,
  constants.PUT_PERSONAL_DATA_SUCCESS,
  constants.POST_PERSONAL_DATA,
  constants.POST_PERSONAL_DATA_SUCCESS,
)