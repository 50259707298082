import { createActions } from 'redux-actions'

import * as constants from '../constants/closeModalAction'

export const { closeModal, showAlertAction, showContentAction, showModalAction } =
  
createActions(
  constants.CLOSE_MODAL,
  constants.SHOW_ALERT_ACTION,
  
)