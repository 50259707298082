import React, { useEffect } from 'react'
import Messengers from '../../layouts/Messengers/Messengers'

import style from './Footer.module.scss'

export default function Footer() {

  return (
    <div className={style.wrapper}>
      <div className={style.line}/>
      <div className={style.wrapContent}>
        <a href="tel:+375259802774" style={{textDecoration: 'none'}}><i className="fas fa-phone-square-alt"></i><b>
        <div className={style.phone}>
          <span className={style.tag}></span> 8(025) 980-27-74
        </div>
        </b></a>
        <Messengers
          direction='row'
          size='28px'
          margin="5px"
        />
      </div>
    </div>
  )
}
