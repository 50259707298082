import React from 'react'

import style from './Articles.module.scss'

export default function Content({id, tytle, text}) {
  return (
    <div className={style.wrapContent}>
        <span className={style.content}>{id}</span>
        <div className={style.wrapText}>
          <span className={style.content}>{tytle}</span>
          <span className={style.text}>{text}</span>
        </div>
    </div>
    
  )
}
