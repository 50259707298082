import React from 'react'
import Calc from './Calc/Calc'
import Portfolio from '../../layouts/Portfolio/Portfolio'
import Footer from '../Footer/Footer'

import style from './CreateSite.module.scss'

export default function Content() {

    return (
        <div className={style.wrapContentMobile}>
            <Calc/>
            <div className={style.wrapTitlePortfolio}>
                <div className={style.titlePortfolio} >
                <span className={style.text}>Портфолио</span>
                </div>
            </div>   
            <Portfolio
                font={true}
            /> 
            <Footer/>
        </div>
    )
}
