import React, { useState } from 'react'
import { CaretLeftOutlined, CloseSquareOutlined, CaretRightOutlined, setTwoToneColor } from '@ant-design/icons'
import ModalWrapper from '../../../wrapers/ModalWrarrer/ModalWrapper'
import ModalEmail from '../../../wrapers/ModalEmail/ModalEmail'
import style from './Calc.module.scss'

export default function Calc() {
  const [choose, setChoose] = useState([])
  const [analitics, setAnalitics] = useState([])
  const [fix, setFix] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [calc, setCalc] = useState(0)
  const [movePage, setMovePage] = useState(false)

  const chooseSite = (arg) => {
    choose[0] = []
    setChoose([...choose[0], arg])
    setTimeout(() => {
      setCalc(calc +1)
    }, 300)
  }
  const chooseAnalitics = (arg) => {
    const find = analitics.find(item => item === arg)
    if(!find){
      setAnalitics([...analitics, arg])
    }
    setTimeout(() => {
      setCalc(calc +1)
    }, 1500)
  }
  const chooseFix = (arg) => {
    const find = fix.find(item => item === arg)
    if(!find){
      setFix([...fix, arg])
    }
  }

  const prev = () => {
    if(calc >= 1){
      setTimeout(() => {
        setCalc(calc -1)
      }, 300)
    }
  }
  const reset = () => {
    if(calc === 0){
      setChoose([])
    }
    if(calc === 1){
      setAnalitics([])
    }
    if(calc === 2){
      setFix([])
    }
  }
  const next = () => {
    if(calc < 2){
      setTimeout(() => {
        setCalc(calc +1)
      }, 300)
    }
  }

  const analiticsResult = analitics.map(item => {
    return item
  })
  const fixResult =  fix.map(item => {
    return item
  })
  const youChoice = `${choose} \n ${analiticsResult} \n ${fixResult}`

  return (
    <div className={style.wrapCalc}>
      <div className={style.wrapTitle}>
        <h2 className={style.titleColor}>Рассчитать стоимость сайта</h2>
      </div>
      <div className={style.calc}>
        <div className={style.content}>
          {
            calc === 0 
            ? ( 
              <div className={style.wrapImg}>
                <div className={style.blocImg}>
                  <div className={style.block}>
                    <img className={style.img} src={require('./images/site1.webp')} rel= "prefetch" loading='lazy' title='website' width='300' height='169' alt="website" draggable="false" 
                      onClick={() => chooseSite('Сайт визитка')}
                    />
                    <span className={style.text}>Сайт визитка</span>
                  </div>
                  <div className={style.block}>
                    <img className={style.img} src={require('./images/site2.webp')} rel= "prefetch" loading='lazy' title='landing page' width='300' height='169' alt="landing page" draggable="false" 
                      onClick={() => chooseSite('Landing page')}
                    />
                    <span className={style.text}>Landing page</span>
                  </div>
                </div>
                <div className={style.blocImg}>
                  <div className={style.block}>
                    <img className={style.img} src={require('./images/site3.webp')} rel= "prefetch" loading='lazy' title='corporate website' width='300' height='169' alt="corporate website" draggable="false" 
                      onClick={() => chooseSite('Корпоративный сайт')}
                    />
                    <span className={style.text}>Корпоративный сайт</span>
                  </div>
                  <div className={style.block}>
                    <img className={style.img} src={require('./images/site4.webp')}  rel= "prefetch" loading='lazy' title='online store' width='300' height='169' alt="online store" draggable="false" 
                      onClick={() => chooseSite('Интернет маназин')}
                    />
                    <span className={style.text}>Интернет маназин</span>
                  </div>
                </div>
              </div>
            )
            : calc === 1
            ?  (
              <div className={movePage ? style.moveWrapImg : style.wrapImg}>
                <div className={style.blocImg}>
                  <div className={style.block}>
                  <img className={style.img} src={require('./images/analitics.webp')} rel= "prefetch" loading='lazy' title='google ads' width='300' height='169' alt="google ads" draggable="false" 
                      onClick={() => chooseAnalitics('Google ads')}
                    />
                    <span className={style.text}>Google ads</span>
                  </div>
                  <div className={style.block}>
                    <img className={style.img} src={require('./images/yandex.webp')} rel= "prefetch" loading='lazy' title='yandex direct' width='300' height='169' alt="andex direct" draggable="false"
                      onClick={() => chooseAnalitics('Yandex Direct')}
                    />
                    <span className={style.text}>Yandex Direct</span>
                  </div>
                </div>
                <div className={style.blocImg}>
                  <div className={style.block}>
                    <img className={style.img} src={require('./images/ads.webp')} rel= "prefetch" loading='lazy' title='gooogle advance' width='300' height='169' alt="gooogle advance" draggable="false" 
                      onClick={() => chooseAnalitics('Gooogle Advance')}
                    />
                    <span className={style.text}>Gooogle Advance</span>
                  </div>
                  <div className={style.block}>
                    <img className={style.img} src={require('./images/metrica.webp')} rel= "prefetch" loading='lazy' title='yandex metrica' width='300' height='169' alt="yandex metrica" draggable="false" 
                      onClick={() => chooseAnalitics('Yandex metrica')}
                    />
                    <span className={style.text}>Yandex metrica</span>
                  </div>
                </div>
              </div>
            )
            : calc === 2
            ?  (
              <div className={movePage ? style.moveWrapImg : style.wrapImg}>
                <div className={style.blocImg}>
                  <div className={style.block}>
                    <img className={style.img} src={require('./images/crm.webp')} rel= "prefetch" loading='lazy' title='CRM' width='300' height='169' alt="CRM" draggable="false"
                        onClick={() => chooseFix('CRM')}
                      />
                      <span className={style.text}>CRM</span>
                  </div>
                  <div className={style.block}>
                    <img className={style.img} src={require('./images/fix.webp')} rel= "prefetch" loading='lazy' title='fix' width='300' height='169' alt="fix" draggable="false"
                      onClick={() => chooseFix('Поддержка Сайта')}
                    />
                    <span className={style.text}>Поддержка Сайта</span>
                  </div>
                </div>
                <div className={style.blocImg}>
                  <div className={style.block}>
                    <img className={style.img} src={require('./images/uniq.webp')} rel= "prefetch" loading='lazy' title='unique design' width='300' height='169' alt="unique design" draggable="false"
                      onClick={() => chooseFix('Уникальный дизайн')}
                    />
                    <span className={style.text}>Уникальный дизайн</span>
                  </div>
                  <div className={style.block}>
                    <img className={style.img} src={require('./images/template.webp')} rel= "prefetch" loading='lazy' title='template site' width='300' height='169' alt="template site" draggable="false"
                      onClick={() => chooseFix('Шаблонный Сайт')}
                    />
                    <span className={style.text}>Шаблонный Сайт</span>
                  </div>
                </div>
              </div>
            )
            : (
              ''
            )
          }
          
          <div className={style.wrapIcons}>
            <CaretLeftOutlined className={style.icons}
              onClick={() => prev()}
            />
            <CloseSquareOutlined className={style.closeIcons}
              onClick={() => reset()}
            />
            <CaretRightOutlined className={style.icons}
              onClick={() => next()}
            />
          </div>
        </div>

        <div className={style.result}>
          <div className={style.wrapText}>
            <span className={style.title}>Ваш выбор:</span>
            <span className={style.text} style={{fontSize: 22}}>{choose}</span>
            {
              analitics.map(item => {
                return <span className={style.text}>{item}</span>
              })
            }
            {
              fix.map(item => {
                return <span className={style.text}>{item}</span>
              })
            }
          </div>
          {
            calc === 2 
            ? (
              <div className={style.wrapBtn}>
                <div className={style.btn}
                  onClick={() => setShowModal(true)}
                ><span className={style.title}>далее</span>
                </div>
              </div>
            )
            : (
              ''
            )
          }
        </div>
      </div>
      <ModalWrapper showModal={showModal}>
        <div className={style.wrapModal}>
          <div className={style.wrapTitle}>
            <span className={style.title}>Форма обратной связи</span> 
            <div
              className={style.close}
              onClick={() => setShowModal(false)}
            ><img src={require('./images/X.png')} style={{ width: 12, height: 12 }} alt="X" draggable="false"/>
            </div>
          </div>
          <ModalEmail>
            <span className={style.title}>Ваш выбор:</span>
            <textarea 
              className={style.textarea} 
              style={{fontSize: 16}} 
              name="message"
              value={youChoice}
            />
            <div className={style.wrapSubmit}
              onClick={() => setShowModal(false)}
            />
          </ModalEmail>
        </div>
      </ModalWrapper>
    </div>
  )
}
